import React from 'react'; // eslint-disable-line import/no-extraneous-dependencies
import Text from 'components/Text';

const Number = ({ amount, currency, ...props }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency
  });
  return <Text {...props} label={formatter.format(amount)} />;
};
Number.defaultProps = {
  currency: 'KES',
  amount: 0
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// eslint-disable-next-line react/jsx-props-no-spreading
export default Number;
